@media screen and (min-width: 900px) {
  .home .site-main {
    background-image: url('../images/DM_Garbsen_Icon_transparent.png');
    background-position: 20px 300px;
    background-position: calc(
        (100vw - var(--max-inline-size, 1440px) - 350px) / 2
      )
      100px;
    background-repeat: no-repeat;
    background-size: 600px;
  }
  .home .image-slider-block .button {
    font-size: 1.3rem;
    padding: 0.5rem 1.5rem;
  }
}
