:root {
  --color-primary: rgb(22, 93, 154);
  --color-primary--transparency: rgba(22, 93, 154, 0.8);
  --color-secondary: rgb(146, 175, 48);
  --color-background-button: var(--color-secondary);
  --color-background-button--hover: rgb(111, 139, 19);
  --color-background-footer: var(--color-primary);
  --color-background-main: rgb(255, 255, 255);
  --color-link: var(--color-primary);
  --color-text-button: rgb(255, 255, 255);
  --color-text-button--hover: rgb(255, 255, 255);
  --color-text-footer: #fff;
  --color-text-main: rgb(20, 20, 20);
  --font-weight-header: 700;
  --text-transform-menu: none;
}
