.mobile_app_ad_section {
  display: flex;
  flex-direction: column;
  gap: var(--s1);
  padding: var(--s4) clamp(var(--s0), 5%, var(--s4));
}

.text{
  text-align: center;
  display: flex; 
  align-items: center;
  justify-content: center;
}

.handy{
  padding: 1rem 0 1rem 0;
  margin-left: auto; 
  margin-right: auto;  
}

.vertical{
  display: none;
}

@media only screen and (min-width: 1000px) {
  .vertical{
    display: block;
    position: relative;
    max-height: 40rem;  
    top: -50%;    
  }
  .horizontal{
    display: none;
  }
  .layout-handy-and-text{
    display: flex; 
    flex-direction: row-reverse;
    max-height: 20rem;
  }

  .mobile_app_ad_section{
    margin: 5rem 0 6rem 0; 
  }
}

@media screen and (max-width: 400px ) {
  .mobile_app_ad_section a {
    margin-left: auto; 
    margin-right: auto;  
  }
}

